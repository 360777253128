/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { logout, reset, toggleNavigation } from '../features/auth/authSlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navigation() {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navigation } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    navigate('/login');
    dispatch(toggleNavigation());
    dispatch(reset());
  };

  return (
    <Disclosure as="nav" className="bg-green-600">
      {({ open }) => (
        <>
          <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-green-50 hover:text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="w-full mx-auto flex items-center justify-center">
                <Link to="/">
                  <div className="flex-shrink-0 flex items-center">
                    {/* <img
                      className="mx-4 h-8 w-auto"
                      src={logo}
                      alt="Workflow"
                    /> */}
                    <p className=" text-green-50 font-semibold text-lg">
                      Trello Clone
                    </p>
                    {/* <p className="hidden md:block text-green-50 font-semibold text-lg">
                      Trello Clone
                    </p> */}
                  </div>
                </Link>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation
                      .filter((item) => item.onAuth === true)
                      .map((item) =>
                        item.name === 'Logout' ? (
                          <Link
                            key={item.name}
                            to={item.to}
                            onClick={onLogout}
                            className={classNames(
                              item.to === location
                                ? 'bg-green-500 text-white'
                                : 'text-gray-300 hover:bg-green-400 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        ) : (
                          <Link
                            key={item.name}
                            to={item.to}
                            className={classNames(
                              item.to === location
                                ? 'bg-green-500 text-white'
                                : 'text-gray-300 hover:bg-green-400 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation
                .filter((item) => item.onAuth === true)
                .map((item) =>
                  item.name === 'Logout' ? (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.to}
                      onClick={onLogout}
                      className={classNames(
                        item.to === location
                          ? 'bg-green-500 text-white'
                          : 'text-gray-300 hover:bg-green-400 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ) : (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.to}
                      className={classNames(
                        item.to === location
                          ? 'bg-green-500 text-white'
                          : 'text-gray-300 hover:bg-green-400 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
                )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
