import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import authService from './authService';

// Get user from local storage
const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user ? user : null,
  navigation: [
    // { name: 'Home', to: '/', current: true, onAuth: true },
    { name: 'Projects', to: '/projects', current: false, onAuth: true },
    // { name: 'Tasks', to: '/tasks', current: false },
    // { name: 'Profile', to: '/profile', current: false, onAuth: true },
    {
      name: 'Login',
      to: '/login',
      current: false,
      onAuth: user ? false : true,
    },
    // {
    //   name: 'Register',
    //   to: '/register',
    //   current: false,
    //   onAuth: user ? false : true,
    // },
    {
      name: 'Logout',
      to: '#',
      current: false,
      onAuth: user ? true : false,
    },
  ],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// register user
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // normal functions => i.e. reset function
  reducers: {
    reset: (state) => {
      state.user = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    toggleNavigation: (state) => {
      state.navigation.forEach((item) => {
        switch (item.name) {
          case 'Login':
            item.onAuth = !item.onAuth;
            break;
          case 'Logout':
            item.onAuth = !item.onAuth;
            break;
          default:
            break;
        }
      });
    },
  },
  // Async thunks => asynchronous functions go there
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload; // return value from thunk
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
        state.user = null;
      })

      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload; // return value from thunk
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
        state.user = null;
      })

      .addCase(logout.fulfilled, (state, action) => {
        state.user = null;
      });
  },
});

export const { reset, toggleNavigation } = authSlice.actions;
export default authSlice.reducer;
