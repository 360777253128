import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { ChevronLeftIcon } from '@heroicons/react/solid';

import { createProject } from '../features/projects/projectSlice';

const AddProject = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    // members: [],
  });

  const [tags, setTags] = useState(['Lorenz', 'Jonathan']);

  // const removeTags = (indexToRemove) => {
  //   setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  // };
  // const addTags = (e) => {
  //   if (e.target.value !== '') {
  //     setTags([
  //       ...tags,
  //       e.target.value.substring(0, e.target.value.length - 1),
  //     ]);
  //     e.target.value = '';
  //   }
  // };

  const { name, description } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      // members: [...tags],
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const projectData = { name, description };

    dispatch(createProject(projectData));

    navigate('/projects');

    //console.log(projectData);
  };

  return (
    <div className="my-24 px-4">
      <div className="mx-auto my-4 p-8 max-w-4xl shadow-xl rounded">
        <div className="mb-4">
          <h1 className="text-center text-2xl md:text-5xl font-bold text-green-600">
            Projekt hinzufügen
          </h1>
        </div>
        <form className="my-4 space-y-6" onSubmit={onSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="name" className="sr-only">
                Projektname
              </label>
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Projektname"
                value={name}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="description" className="sr-only">
                Projektbeschreibung
              </label>
              <textarea
                id="description"
                name="description"
                type="text"
                autoComplete="description"
                required
                className="appearance-none h-24 rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Projektbeschreibung"
                value={description}
                onChange={onChange}
              />
            </div>
          </div>

          {/* <div className="mt-6 mb-12 rounded-md shadow-sm -space-y-px">
            <div className="relative">
              <label htmlFor="members" className="sr-only">
                Projekt Mitglieder
              </label>
              <input
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Mitglieder eintragen - Komma drücken um ein Mitglied hinzuzufügen"
                onKeyUp={(e) => (e.key === ',' ? addTags(e) : null)}
                id="members"
                name="members"
                type="text"
                autoComplete="members"
                onChange={onChange}
              />
              {tags.map((tag, index) => (
                <div
                  className="bg-green-400 inline-flex items-center text-sm rounded-full mt-2 mr-1 overflow-hidden"
                  key={index}
                >
                  <span
                    className="ml-2 mr-1 leading-relaxed truncate max-w-xs px-1"
                    x-text="tag"
                  >
                    {tag}
                  </span>

                  <button
                    className="w-6 h-8 inline-block align-middle text-gray-500 bg-green-500 focus:outline-none"
                    onClick={() => removeTags(index)}
                  >
                    <svg
                      className="w-6 h-6 fill-current mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div> */}

          <div>
            <button
              type="submit"
              className="mx-auto group relative w-2/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Jetzt hinzufügen
            </button>
          </div>
        </form>
      </div>

      <div className="flex justify-center my-12">
        <Link
          to={`/projects`}
          className="px-3 py-1 text-white bg-green-600 hover:bg-green-500 rounded-md inline-flex items-center text-sm"
        >
          <ChevronLeftIcon className="w-5 h-5 ml-0 mr-1 text-white" />
          Zurück zur Projektübersicht
        </Link>
      </div>
    </div>
  );
};
export default AddProject;
