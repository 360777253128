import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ProjectTask from '../components/ProjectTask';
import Spinner from '../components/Spinner';
import { v4 as uuidv4 } from 'uuid';

import { PlusIcon, XIcon, TrashIcon } from '@heroicons/react/solid';

import { useDispatch, useSelector } from 'react-redux';
import {
  getUserTasks,
  getProjectName,
  setTasks,
  createTask,
  setShowInput,
  createCategory,
  deleteCategory,
  updateTask,
} from '../features/tasks/taskSlice';
import { useParams } from 'react-router-dom';

function Board() {
  const dispatch = useDispatch();
  const params = useParams();

  const { categories, isError, isLoading, message, projectName } = useSelector(
    (state) => state.tasks
  );

  useEffect(() => {
    if (isError) {
      //console.log(message);
    }
    //console.log('useEffect');
    dispatch(getUserTasks(params.id));
    dispatch(getProjectName(params.id));
  }, [isError, message, dispatch, params.id]);

  // Add a new task to a column
  const [newTaskData, setNewTaskData] = useState({
    id: '',
    taskName: '',
  });

  const { taskName } = newTaskData;

  const newTaskDataChange = (e) => {
    setNewTaskData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const newTaskDataSubmit = (e, index, categoryID) => {
    e.preventDefault();
    //console.log(index);

    const taskData = { id: uuidv4(), name: taskName };

    //console.log(taskData);

    let data = { newTask: taskData, index, categoryID };

    dispatch(createTask(data));
    dispatch(getUserTasks(params.id));
    setNewTaskData({ id: '', taskName: '' });
  };

  // Add new Category
  const [newCategoryData, setNewCategoryData] = useState({
    categoryId: '',
    categoryName: '',
    tasks: [],
  });

  const { categoryName } = newCategoryData;

  const newCategoryDataChange = (e) => {
    setNewCategoryData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const newCategoryDataSubmit = (e) => {
    e.preventDefault();
    let newData = { id: uuidv4(), name: categoryName, tasks: [] };

    const data = {
      category: newData,
      projectID: params.id,
    };

    dispatch(createCategory(data));
    dispatch(getUserTasks(params.id));
    setNewCategoryData({ categoryId: '', categoryName: '', tasks: [] });
  };

  const onCategoryDelete = (categoryID) => {
    dispatch(deleteCategory(categoryID));
  };

  const onDragEnd = (result, categories) => {
    console.log('Drag and Drop');
    const newCategories = JSON.parse(JSON.stringify(categories));

    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = newCategories.find(
        (column) => column.id === source.droppableId
      );

      const destColumn = newCategories.find(
        (column) => column.id === destination.droppableId
      );

      const sourceItems = sourceColumn.tasks;

      let destItems = destColumn.tasks;

      let updatedTask = sourceItems.find((task) => task.id === draggableId);
      //console.log(updatedTask);

      //console.log(destColumn.id);

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      const data = {
        updatedTask,
        destColumn,
      };
      dispatch(updateTask(data));
      dispatch(setTasks([...newCategories]));
    } else {
      let column = newCategories.find(
        (column) => column.id === source.droppableId
      );
      //console.log(column);
      let copiedItems = column.tasks;
      //console.log(copiedItems);
      const [removed] = copiedItems.splice(source.index, 1);
      //console.log(removed);
      copiedItems.splice(destination.index, 0, removed);
      //console.log(column.tasks);
      column.tasks = copiedItems;
      dispatch(setTasks([...newCategories]));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="my-8">
      <h1 className="text-center text-4xl md:text-5xl font-bold text-green-600 my-6">
        {projectName}
      </h1>
      <div className="p-4 shadow-xl rounded mx-auto max-w-xl mb-8">
        <p className="text-center text-2xl font-semibold text-green-600">
          Neue Kategorie hinzufügen
        </p>

        <form className="my-4 space-y-6" onSubmit={newCategoryDataSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="categoryName" className="sr-only">
                Name der Kategorie
              </label>
              <input
                id="categoryName"
                name="categoryName"
                type="text"
                autoComplete="categoryName"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Name der Kategorie"
                value={categoryName}
                onChange={newCategoryDataChange}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="mx-auto group relative w-2/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Kategorie hinzufügen
            </button>
          </div>
        </form>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-2 mx-auto">
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result, categories);
          }}
        >
          {categories.map((column, index) => {
            return (
              <div key={column.id} className="flex flex-col items-center">
                <h2 className="text-center text-xl font-semibold my-4">
                  {column.name}
                </h2>
                <div className="mb-8">
                  <div
                    className="rounded bg-green-400 p-2 mx-auto
                   w-80 max-w-xs flex flex-col items-center justify-center"
                  >
                    <div>
                      <button
                        className="inline-flex items-center hover:bg-lime-400 rounded p-2"
                        onClick={() => onCategoryDelete(column.id)}
                      >
                        <TrashIcon className="w-5 h-5 text-red-600" />
                        Kategorie entfernen
                      </button>
                    </div>
                    <Droppable key={column.id} droppableId={column.id}>
                      {(provided, snapshot) => (
                        <div
                          className="rounded p-4 h-full w-full"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            background: snapshot.isDraggingOver
                              ? 'limegreen'
                              : '',
                          }}
                        >
                          {column.tasks.map((item, index) => (
                            <ProjectTask
                              key={item.id}
                              item={item}
                              index={index}
                            />
                          ))}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    {column.showInput ? (
                      <div className="rounded-md px-4 my-2 w-full p-2">
                        <form
                          className="my-4 space-y-6"
                          onSubmit={(e) =>
                            newTaskDataSubmit(
                              e,
                              categories.indexOf(column),
                              column.id
                            )
                          }
                        >
                          <div>
                            <label htmlFor="taskName" className="sr-only">
                              Task Beschreibung {column.showInput}
                            </label>
                            <textarea
                              id="taskName"
                              name="taskName"
                              type="text"
                              autoComplete="taskName"
                              required
                              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                              placeholder="Task Beschreibung"
                              value={taskName}
                              onChange={newTaskDataChange}
                            />
                            <div className="flex justify-between items-center my-1">
                              <button
                                className="bg-lime-400 text-white px-3 py-1 rounded"
                                type="submit"
                              >
                                Task hinzufügen
                              </button>
                              <button
                                className="bg-lime-400 text-white py-2 px-3 rounded"
                                onClick={() =>
                                  dispatch(setShowInput(column.id))
                                }
                              >
                                <XIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="my-2 flex justify-center">
                        <button
                          className="px-3 py-2 rounded bg-lime-400 text-white shadow-md"
                          onClick={() => dispatch(setShowInput(column.id))}
                        >
                          <PlusIcon className="w-5 h-5" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
}

export default Board;
