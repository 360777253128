import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import projectService from './projectService';

const initialState = {
  projects: [],
  projectMembers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// create new project
export const createProject = createAsyncThunk(
  'projects/createProject',
  async (projectData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.createProject(projectData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get user projects
export const getUserProjects = createAsyncThunk(
  'projects/getUserProjects',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.getUserProjects(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get members of the projects
export const getMemberOfProjects = createAsyncThunk(
  'projects/getMemberOfProjects',
  async (projectID, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.getMemberOfProjects(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create new project
export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (projectID, thunkAPI) => {
    console.log(projectID);
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.deleteProject(projectID, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProject.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects.push(action.payload); // return value from thunk
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(getUserProjects.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload; // return value from thunk
      })
      .addCase(getUserProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(getMemberOfProjects.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMemberOfProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectMembers = action.payload; // return value from thunk
      })
      .addCase(getMemberOfProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(deleteProject.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const id = action.payload;
        console.log(id);
        state.projects = state.projects.filter(
          (project) => project.project_id !== id
        ); // return value from thunk
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      });
  },
});

export const { reset } = projectSlice.actions;
export default projectSlice.reducer;
