import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import taskService from './taskService';

const initialState = {
  categories: [],
  projectName: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// get user tasks
export const getUserTasks = createAsyncThunk(
  'tasks/getUserTasks',
  async (userID, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    try {
      return await taskService.getUserTasks(userID, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get project name
export const getProjectName = createAsyncThunk(
  'tasks/getProjectName',
  async (userID, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    try {
      return await taskService.getProjectName(userID, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createCategory = createAsyncThunk(
  'tasks/createCategory',
  async (data, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    //console.log(data);
    try {
      return await taskService.createCategory(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'tasks/deleteCategory',
  async (categoryID, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    //console.log(categoryID);
    try {
      return await taskService.deleteCategory(categoryID, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create new task
export const createTask = createAsyncThunk(
  'tasks/createTask',
  async (data, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    try {
      return await taskService.createTask(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update tasks
export const updateTask = createAsyncThunk(
  'tasks/updateTask',
  async (data, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    //console.log(data);
    try {
      return await taskService.updateTask(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete tasks
export const deleteTask = createAsyncThunk(
  'tasks/deleteTasks',
  async (taskID, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;

    try {
      return await taskService.deleteTask(taskID, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    reset: (state) => initialState,
    setTasks: (state, action) => {
      state.categories = action.payload;
    },
    setShowInput: (state, action) => {
      const categoryID = action.payload;
      //console.log(categoryID);
      let categories = state.categories;

      state.categories = state.categories.forEach((cat) => {
        if (cat.id === categoryID) {
          cat.showInput = !cat.showInput;
        }
      });
      state.categories = categories;
    },
    filterTasks: (state, action) => {
      //console.log(action.payload);
      const taskID = action.payload;
      state.categories.forEach((category) => {
        category.tasks = category.tasks.filter((task) => {
          return task.id !== taskID;
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTasks.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload; // return value from thunk
      })
      .addCase(getUserTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(getProjectName.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectName = action.payload; // return value from thunk
      })
      .addCase(getProjectName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(createCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        //console.log(action.payload);
        state.categories.push(action.payload); // return value from thunk
        //console.log(state.categories);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(deleteCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const id = action.payload;
        // console.log(id);
        // console.log(id.substring(4, id.length));
        //console.log(typeof id);
        //console.log(id.substring(4, id.length));
        state.categories = state.categories.filter(
          (cat) =>
            cat.id.substring(4, cat.id.length) !== id.substring(4, id.length)
        ); // return value from thunk
        //console.log(state.categories);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(createTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const { newTask, categoryID } = action.payload;
        //console.log(newTask);
        // console.log(index);
        // console.log(state.categories[index]);
        state.categories
          .find((cat) => cat.id === categoryID)
          .tasks.push(newTask);
        //state.categories[index].tasks.push(newTask); // return value from thunk
      })
      .addCase(createTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      })

      .addCase(updateTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        //console.log(action.payload);
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
        //console.log(action);
      })

      .addCase(deleteTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const taskID = action.payload;
        state.categories.forEach((category) => {
          category.tasks = category.tasks.filter((task) => {
            return task.id !== taskID;
          });
        }); // return value from thunk
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // returned error from thunk ==> rejectWithValue
      });
  },
});

export const { reset, setTasks, filterTasks, setShowInput } = taskSlice.actions;
export default taskSlice.reducer;
