import {
  TrashIcon,
  // PencilIcon,
  // ChevronRightIcon,
  // ChevronLeftIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/solid';

import { Draggable } from 'react-beautiful-dnd';

import { useDispatch } from 'react-redux';
import { deleteTask } from '../features/tasks/taskSlice';

const Task = ({ item, index }) => {
  const dispatch = useDispatch();

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="my-2 p-2 rounded bg-white shadow-lg text-lg w-64 mx-auto"
        >
          <div className="my-4">
            <p className="text-sm">{item.name}</p>
          </div>

          <div className="mt-4">
            <div className="flex justify-between items-center">
              <div className="pl-1 inline-flex items-center space-x-1">
                {item.deadline ? (
                  <ShieldExclamationIcon className="w-5 h-5 text-orange-600" />
                ) : null}

                <p className="text-xs">
                  {item.deadline ? 'Deadline: ' + item.deadline : ''}
                </p>
              </div>
              <button
                className="hover:bg-gray-300 rounded-full p-1"
                onClick={() => dispatch(deleteTask(item.id))}
              >
                <TrashIcon className="w-5 h-5 text-red-700" />
              </button>
              {/* <button className="hover:bg-gray-300 rounded-full p-1">
                <PencilIcon className="w-5 h-5 text-orange-500" />
              </button> */}
            </div>
            {/* <div className="flex -space-x-1 overflow-hidden justify-end">
              <img
                className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <img
                className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <img
                className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                alt=""
              />
              <img
                className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div> */}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Task;
