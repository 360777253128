import React from 'react';
import ProjectsList from '../components/ProjectsList';

import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PlusIcon } from '@heroicons/react/solid';

import Spinner from '../components/Spinner';

import {
  getUserProjects,
  getMemberOfProjects,
} from '../features/projects/projectSlice';

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useSelector((state) => console.log('state', state));

  const { user } = useSelector((state) => state.auth);
  const { projects, isLoading, isError, message } = useSelector(
    (state) => state.projects
  );

  const { projectMembers } = useSelector((state) => state.projects);

  useEffect(() => {
    if (isError) {
      // console.log(message);
    }
    if (!user) {
      navigate('/login');
    }

    dispatch(getUserProjects());

    dispatch(getMemberOfProjects());

    // clear projects on component unmount
    // return () => {
    //   dispatch(reset());
    // };
  }, [user, navigate, isError, message, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="my-8">
      <div className="mb-4">
        <h1 className="text-center text-4xl md:text-5xl font-bold text-green-600">
          Übersicht deiner Projekte
        </h1>
      </div>

      <div className="mx-auto w-full my-8 flex flex-col space-y-2 justify-start items-center">
        <Link
          to={`/projects/add`}
          className="inline-flex p-4 rounded-full bg-green-600 hover:bg-green-500 text-white"
        >
          <PlusIcon className="w-5 h-5 text-white" />
        </Link>
      </div>

      <ProjectsList projects={projects} projectMembers={projectMembers} />
    </div>
  );
};

export default Projects;
