import React from 'react';
import Project from './Project';

const ProjectsList = ({ projects, projectMembers }) => {
  return (
    <div className="my-8 container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {projects.map((project, i) => (
          <Project
            key={project.project_id}
            project={project}
            projectMembers={projectMembers}
            index={i}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsList;
