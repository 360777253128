import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

// import Footer from './components/Footer';

import Navigation from './components/Navigation';
import ProjectBoard from './pages/ProjectBoard';

// import Home from './pages/Home';
// import Profile from './pages/Profile';
// import ProjectOverview from './pages/ProjectOverview';
import Projects from './pages/Projects';

// import Register from './pages/Register';
import Login from './pages/Login';
import AddProject from './pages/AddProject';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function App() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return (
    <>
      <Navigation />

      {/* {!user && <Navigate to="/login" />} */}

      <div className="container h-screen mx-auto">
        <Routes>
          <Route
            path="/"
            exact
            redi
            element={<Navigate to="/projects" />}
          ></Route>
          {/* <Route path="/register" exact element={<Register />}></Route> */}
          <Route path="/login" exact element={<Login />}></Route>
          <Route path="/projects" exact element={<Projects />}></Route>
          <Route path="/projects/add" exact element={<AddProject />}></Route>
          {/* <Route
            path="/projects/overview/:id"
            exact
            element={<ProjectOverview />}
          ></Route> */}
          <Route path="/projects/:id" exact element={<ProjectBoard />}></Route>

          {/* <Route path="/profile" exact element={<Profile />}></Route> */}
          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routes>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;
