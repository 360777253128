import { Link } from 'react-router-dom';

import { TrashIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { deleteProject } from '../features/projects/projectSlice';

const Project = ({ project, projectMembers, index }) => {
  const membersObject = projectMembers.find(
    (p) => p.project_id === project.project_id
  );
  let members = [];
  if (membersObject) members = membersObject.members;
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(deleteProject(project.project_id));
  };

  return (
    <div className="px-8 my-4">
      <Link to={`/projects/${project.project_id}`}>
        <div className="px-6 py-4 shadow-lg bg-green-400 bg-opacity-100 rounded max-w-18 max-h-70 hover:bg-green-500 ease-in-out duration-75">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold text-white">{project.name}</p>
            {/* <Link
              to={`/projects/overview/${project.project_id}`}
              className="inline-flex items-center hover:bg-lime-400 rounded-full p-1"
            >
              <InformationCircleIcon className="w-6 h-6 text-white" />
            </Link> */}
            {index >= 4 ? (
              <Link
                className="inline-flex items-center hover:bg-lime-400 rounded-full p-1"
                to={'#'}
                onClick={onDelete}
              >
                <TrashIcon className="w-5 h-5 text-red-600" />
              </Link>
            ) : (
              <div></div>
            )}
          </div>
          <hr className="my-2" />
          <div className="my-2">
            <p className="text-white font-bold">Beschreibung</p>
            <p className="my-2 text-sm">{project.description}</p>
          </div>

          <div className="mt-4">
            <p className="text-white font-bold">Mitglieder</p>
            <div className="flex -space-x-2 overflow-hidden my-2">
              {members.map((member) => (
                <div
                  key={member.user_id}
                  className={`inline-block h-9 w-9 hover:bg-lime-200 rounded-full text-center bg-gray-100 font-bold pt-1 border-2 border-white circle-with-init ${
                    member.is_admin ? 'border-2 bg-lime-300' : ''
                  }`}
                  title={
                    member.username +
                    (member.is_admin ? ' (Administrator)' : '')
                  }
                >
                  {member.username.substr(0, 1).toUpperCase()}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Project;
