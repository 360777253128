import axios from 'axios';

const API_URL = '/api/projects/';

// create new project
const createProject = async (projectData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL, projectData, config);

  return response.data;
};

const getUserProjects = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

const getMemberOfProjects = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'projectMembers/', config);

  return response.data;
};

const deleteProject = async (projectID, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + projectID, config);

  return response.data;
};

const projectService = {
  createProject,
  getUserProjects,
  getMemberOfProjects,
  deleteProject,
};

export default projectService;
