import axios from 'axios';

const Project_URL = '/api/projects/';
const TASK_URL = '/api/tasks/';
const CATEGORY_URL = '/api/categories/';

const getUserTasks = async (projectID, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(Project_URL + projectID, config);

  response.data.categories.forEach((cat) => {
    cat.showInput = false;
  });

  //console.log(response.data.categories);

  return response.data.categories;
};

const getProjectName = async (projectID, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(Project_URL + projectID, config);

  response.data.categories.forEach((cat) => {
    cat.showInput = false;
  });

  //console.log(response.data.name);

  return response.data.name;
};

const createCategory = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const queryData = {
    name: data.category.name,
    project_id: parseInt(data.projectID),
  };

  await axios.put(CATEGORY_URL, queryData, config);

  return data.category;
};

const deleteCategory = async (categoryID, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios.delete(
    CATEGORY_URL + categoryID.substring(4, categoryID.length),
    config
  );

  return categoryID;
};

const createTask = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const queryData = {
    name: data.newTask.name,
    category_id: data.categoryID.replace('cat-', ''),
  };

  const response = await axios.put(TASK_URL, queryData, config);

  const taskToPush = {
    newTask: {
      name: data.newTask.name,
      id: response.data.task_id.toString(),
    },
    index: data.index,
    categoryID: data.categoryID,
  };

  return taskToPush;
};

const updateTask = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //console.log(data);

  let queryData = {
    name: data.updatedTask.name,
    category_id: data.destColumn.id.replace('cat-', ''),
  };

  //console.log(TASK_URL + data.updatedTask.id);

  const response = await axios.post(
    TASK_URL + data.updatedTask.id,
    queryData,
    config
  );

  return response.data;
};

const deleteTask = async (taskID, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios.delete(TASK_URL + taskID, config);

  return taskID;
};

const taskService = {
  getUserTasks,
  getProjectName,
  createCategory,
  deleteCategory,
  createTask,
  updateTask,
  deleteTask,
};

export default taskService;
